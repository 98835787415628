.card {
    margin-bottom: 3px !important;
}
.custom-modal-style_clz {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.badge-secondary_clz {
    color: #808080 !important;
    background-color: antiquewhite !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-success__clz {
    color: #20b021 !important;
    background-color: #b1eac0 !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-pill-secondary__clz {
    padding-right: 3.6em !important;
    padding-left: 2.6em !important;
}
.badge-danger {
    color: #ff0000 !important;
    background-color: #ffe2e2 !important;
}
.badge-style_clz {
    color: #8811f9 !important;
    background-color: #ebdffe !important;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4 !important;
}

.style-button-planing__clz:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #8811f9 !important;
    background-color: #ebdffe !important;
    border-color: #8811f9 !important;
}
.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
