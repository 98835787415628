.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}
.card-monetic_associate__clz {
    margin: 1px !important;
    border: 1px solid rgb(220, 220, 220) !important;
    height: auto;
}
.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
}
