.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #74788d !important;
}
.card__clz {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgb(220, 220, 220);
    margin-bottom: 7px !important;
}
.card-modal-component__clz {
    display: grid;
    grid-auto-columns: 7.8fr 0.8fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    /* min-height: 658px; */
}
.card_modale__clz {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.5fr 1fr;
    column-gap: 0px;
    border: 2px none;
}
.card-model-first__clz {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 1px;
    border: 1px solid #eff2f7 !important;
    background-color: #eaeaea;
    height: 587px;
}
.card-model-second__clz {
    margin: 1px !important;
    border: 1px solid #eff2f7 !important;
    max-height: 587px !important;
}
.border__clz {
    border-bottom: 1px solid rgb(220, 220, 220);
}
.style-btn-model__clz {
    width: 18%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
}
.style-model__clz {
    font-weight: bold !important;
}
.style-span__clz {
    font-weight: bold !important;
}
.style-logo__clz {
    height: 50vh;
}
.Styled-button__clz {
    width: 10px;
}
.Card-footer__clz {
    background-color: white;
}
.btn-model__clz:focus {
    background: black;
    color: white;
}
.btn-model__clz:after {
    background: black;
    color: white;
}
.check-style__clz {
    width: 30px;
    height: 31px;
    background: #333131 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bcbcbc;
    border-radius: 18px;
    margin-bottom: 530px;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1b1b1c;
    border-color: #74788d;
}

.style-card-button-model {
    height: 60px;
    border: 1px solid #c6c6c6;
    opacity: 1;
}
