.customer-account-menu-nav-link-active__clz.nav-link.active {
    color: rgb(14, 14, 15) !important;
    border: none !important;
}

.customer-account-menu-nav-link-disable__clz.nav-link {
    border: none !important;
    color: rgb(14, 14, 15) !important;
}

.active-nav-item__clz {
    border-bottom: 3px solid rgb(11, 11, 11);
}

.disable-nav-item__clz.nav-link {
    border-bottom: 1px solid #ddd4d4 !important;
}

.index-shadow__clz {
    box-shadow: 0px 3px 6px #00000029;
}

.disable-account-creation-items__clz {
    pointer-events: none;
    opacity: 0.36;
}
.reset__clz {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
