.tags-swiper-width__clz {
    height: 23px !important;
    background: #33333326 0% 0% no-repeat padding-box;
    border-radius: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content !important;
    padding: 0 10px !important;
}

.active-tags-swiper-width__clz {
    height: 23px !important;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content !important;
    padding: 0 10px !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tags-swiper__clz.swiper-container {
    width: calc(calc(100vh - 210px) / 2);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: unset !important;
    margin-right: unset !important;
    height: 10px;
}

.tags-swiper__clz .swiper-wrapper {
    align-items: center;
    width: calc(calc(100vh - 200px) / 2);
    gap: 5px;
    z-index: -1;
}
