.drag-list-wrapper__clz {
    grid-area: drag-list;
    display: grid;
    grid-template-rows: 55px auto;
}

.draggable-element-wrapper__clz {
    display: grid;
    grid-template-columns: 200px auto;
    height: 100%;
}

.ways-item__clz {
    display: grid;
    grid-template-rows: 568px 65px;
    grid-template-columns: 315px;
    border-radius: 1rem;
}

.elements-item__clz {
    display: grid;
    grid-template-rows: 1.9fr 0.1fr;
}

.ways-item-index__clz {
    display: grid;
    grid-template-columns: 0.2fr 1.8fr;
    font-size: 16px;
    box-shadow: 0px 3px 6px #00000029;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.close-button__clz {
    background-color: red !important;
    z-index: 1;
    border-top-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.close-button__clz:hover {
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: none !important;
}
