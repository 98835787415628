:root {
    --page-background: white;
    --scrollbar-track: transparent;
    --scrollbar-thumb: rgb(191, 193, 186);
    --scrollbar-thumb-hover: rgb(110, 108, 108);
    --scrollbar-width: 12px;
}

* {
    scrollbar-width: auto;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

*::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-width);
}

*::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
    border-radius: var(--scrollbar-width);
    border: calc(var(--scrollbar-width) / 4) solid var(--page-background);
}

*::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover);
}

textarea {
    outline-color: #719ece;
}
