.keyboard-printer.simple-keyboard {
    max-width: 975px;
}

.keyboard-printer__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
}

.keyboard-printer__clz.hg-theme-default .hg-button {
    width: 18px;
    height: 30px;
    text-align: center;
    font: normal normal bold 12px/14px Arial;
    letter-spacing: 0px;
    color: #000000;
}

.space-key__clz {
    width: 50% !important;
}
