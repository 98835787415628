.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.popup-css_clz {
    max-width: 508px !important;
    width: 508px !important;
}
.not-allowed-input-vr__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: #c7cdcd !important;
}
