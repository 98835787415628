.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
}

.col-icon-notification__clz {
    background-color: #292929;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.text-notification__clz {
    font: normal normal normal 20px/27px Segoe UI;
}

.card-header__clz,
.card-footer__clz {
    padding: 0px !important;
    background-color: transparent !important;
}

.message-info__clz {
    display: none !important;
}
.notification-text__clz {
    font: normal normal normal Segoe UI;
    font-size: 1rem;
}
