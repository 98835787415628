@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

.headerSummary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 19px;
    height: 180px;
    width: 380px;
    margin-top: 11px;
    margin-left: 23px;
}

.centered-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-centered-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart-logo {
    height: 181px;
    width: 181px;
}

.header-text {
    display: flex;
    flex-direction: column;
    padding-left: 2px;
}

.summary-cart-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    scrollbar-color: gray #e0e0e0;
}

.footer-summary-cart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: flex-start;
    padding-left: 3px;
    padding-right: 3px;
    height: 180px;
}

.first-block-footer-summary-cart {
    display: flex;
    padding-right: 3px;
    height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shopping-cart {
    height: 87px;
    width: 87px;
}

.config-option {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.sales-method-items {
    display: flex;
    gap: 10px;
}

.vertical-separation-line {
    height: 60px;
    width: 1px;
    background-color: #cccccc;
}

.option-config-items {
    height: 65px;
    width: 65px;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 15px;
}
.summary-actions {
    display: flex;
    height: 96px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.first-button {
    height: 73px;
    width: 435px;
    font: normal normal 600 27px/36px Segoe UI;
    border-radius: 15px;
}

.pay-button {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    width: 591px;
    height: 73px;
    border-radius: 15px;
    font: normal normal bold 30px/41px Nunito;
    color: #ffffff;
}

.text-confirmed-alert {
    text-align: center;
    height: 334px;
    font-size: 45px;
    gap: 10px;
}

.basic-compositions-component {
    font: normal normal normal 10px/10px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 8px;
}

.variants-component {
    /* UI Properties */
    font: normal normal normal 9px/8px Segoe UI;
    color: #3cc73a;
}
