.page-card-carte:hover .page-content_Carte {
    background: transparent linear-gradient(180deg, #3a3a3a 0%, #5b5b5b 100%) 0%
        0% no-repeat padding-box !important;
    box-shadow: 0px 5px 10px #00000042;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    opacity: 1;
}
.page-card-carte:hover .card-title {
    color: #ffffff !important ;
}
.page-card-carte:hover .card-text {
    color: #ffffff !important ;
}
.page-card-carte :hover .select_carte {
    opacity: 0 !important ;
}
.home-page-content__clz {
    height: 93vh !important ;
}
.page-card-carte :hover.icon {
    fill: #cf080842 !important ;
    stop-color: #cf080842 !important ;
    background: transparent
        linear-gradient(180deg, #cf080842 0%, #cf080842 100%) 0% 0% no-repeat
        padding-box !important;
}
@media (max-width: 1894px) {
    .T-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
.page-content-Home__clz {
    margin-left: 0px !important;
    overflow: hidden !important;
}
