.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.information_aggregator__clz {
    width: 551px !important;
    height: 625px !important;
}
.information_aggregator_modal__clz {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    width: 0px !important;
    pointer-events: none !important;
    background-color: transparent !important;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    margin-left: 709px;
    margin-right: 516px;
    margin-bottom: 0px;
}
.not-allowed-aggregator__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: white !important;
}
