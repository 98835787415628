input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button;
    opacity: 0;
}

input[type="number"]::-webkit-inner-spin-button:hover {
    -webkit-appearance: inner-spin-button;
    opacity: 1;
    margin-left: 1px;
}

.input-container {
    margin-bottom: 10px;
}

.input-container.focused input {
    border-color: blue;
}
