@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap");

.config-main-content__clz {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr auto;
    grid-template-areas: "title" "drag-list";
}

.nav-item-active__clz {
    background-color: rgba(0, 0, 0, 0.429);
}

.template-card__clz:hover {
    box-shadow: -2px 2px 12px -3px rgba(0, 0, 0, 0.5);
}

.wrapper__clz {
    font-family: "Nunito Sans", sans-serif !important;
}

.invalid__clz {
    border-color: #eb2020 !important;
    border-style: solid;
    border-width: 1px;
}

.disable-account-creation-items__clz {
    pointer-events: none;
    opacity: 0.36;
}
