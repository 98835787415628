.background {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 4000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(4, 4, 4, 0.5);
}

.click-capturer {
    display: inline-block;
    position: relative;
    height: 940px;
    margin: 15px;
    margin-top: 20vh;
}

/* .close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
} */
