.form-check-label {
    margin-top: 3px;
    margin-bottom: 4px;
    font-size: 17px;
}
.num__clz {
    height: 40px;
    width: 290px;
    text-align: center;
    align-items: center;
    color: black;
}

span.react-numeric-input b {
    display: none;
}
