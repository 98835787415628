.menu__clz {
    width: 0 !important;
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.dropdown-menu {
    min-width: 5rem !important;
}
.language-switch {
    top: 5px !important;
    left: 12px !important;
}
.header_wrapper__clz {
    position: fixed !important;
}
.styled_h1__clz {
    width: 71px !important;
}
.footer__clz {
    margin-left: 65px !important;
    display: flex;
    position: fixed;
}
.mobile__clz {
    margin-left: 0px !important;
    display: flex;
}
.search__clz {
    display: d-none;
}
.search-icon__clz {
    fill: #161617 !important;
    position: absolute;
    z-index: 10;
    left: 10px;
    width: 20px !important;
}
.vertical-menu__clz {
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    text-align: left;
    display: inline-block;
}
.main-content {
    margin-left: 274px;
    overflow: hidden;
}
.footer {
    position: fixed;
    right: 0;
    color: #212529;
    left: 250px !important;
    background-color: #efeff5;
    display: flex;
}

.navbar-brand-box__clz {
    width: 273px !important;
}
.main-content__clz {
    margin-left: 272px !important;
    overflow: hidden !important;
}

.footerSettings__clz {
    position: fixed;
    right: 0;
    color: #212529;
    left: 1px;
    background-color: #efeff5;
    display: flex;
}

.line1__clz {
    font-size: 18px;
    font-weight: bold;
}

.line2__clz {
    font-size: 14px;
}
