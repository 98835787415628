.Toastify__toast-container--top-center {
    width: 50%;
}

.Toastify__toast-body {
    height: 0px;
}

.page-content__clz {
    padding: calc(40px + 24px) calc(24px / 2) 0px calc(6px / 2);
    background-color: #f3f3f9;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}

.form-check-label__clz .form-check-label {
    margin-top: 10px !important;
}
