.cercle__clz {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    border: 1px solid #000;

    opacity: 1;
}
.cercleactive__clz {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: black 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #ffffff;
    color: red;

    border: 1px solid #000;
    opacity: 1;
}
