.swiper-wrapper__clz .swiper-wrapper {
    margin-left: 20px !important;
}

.template-swiper-slide__clz.swiper-slide {
    height: 320px;
    width: 220px !important;
}

.template-swiper-slide-img__clz.swiper-slide img {
    display: block;
    width: 100%;
    height: 80%;
}

.slide-color__clz {
    background-color: rgb(50, 51, 51) !important;
    color: white !important;
}
.slide-No-color__clz {
    background-color: rgb(232, 238, 238) !important;
    color: rgb(10, 10, 10) !important;
}

.advanced-nav-item-active__clz {
    border-color: #e5e5e5 !important;
}

.nav-tabs-active-link__clz.nav-link.active {
    color: rgb(50, 51, 51) !important;
    background-color: unset !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom: 5px solid rgb(50, 51, 51) !important;
}

.sales-methods-nav-link__clz.nav-link {
    color: #121212 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: center;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.active-model-item__clz {
    border-bottom: 6px solid rgb(50, 51, 51) !important;
}

.active-model-item-img__clz {
    border-radius: 6px 6px 0px 0px !important;
}

.model-items__clz {
    display: grid;
    grid-template-rows: 230px 20px;
    gap: 5px;
}

.design-active-tab__clz {
    background-color: #eaeaea;
}

.design-container__clz {
    display: grid;
    grid-template-columns: 1100px 460px;
    height: 752px;
    gap: 20px;
}

.swiper-slide-width__clz {
    width: 150px !important;
    margin-left: 10px;
    text-align: center;
    background-color: #e9e9e9;
    white-space: nowrap;
    color: #6a6a6a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-wrapper__clz {
    border: 2px solid #0024f9;
    border-radius: 11px;
}

.border-wrapper-banner__clz {
    border: 2px solid #0024f9;
    border-radius: 11px;
    height: 724px;
}

.payment-consummation__clz {
    margin-top: 150px;
}

.nav-link-hover__clz.nav-link {
    border: none !important;
}
