.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.border-footer-kds__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 7px !important;
}

.menu-configuration_kds__clz:hover {
    background-color: #ffffff5e;
    border-left: solid;
    border-left-color: currentcolor;
    border-color: white;
}
