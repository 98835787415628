.modal-dialog__clz.modal-dialog {
    max-width: 660px !important;
}

.modal-dialog__clz .modal-content {
    height: 260px !important;
}

.cancel-btn-hover__clz:hover {
    background-color: #e30613 !important;
    border-color: #e30613 !important;
}
