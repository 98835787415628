input[type="file"] {
    display: none;
}

.file-uploader__clz {
    border: none;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
