.container__clz {
    height: 255px;
    width: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1em !important;
}
.item {
    width: 10em;
}

.modal-wrapper-hours__clz .modal-content {
    height: 80vh !important;
    width: 35vw !important;
}
