.custom-modal-style_clz {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}
.modal-assoc__clz > .modal-content {
    max-height: 89vh !important;
}
