.required__clz:after {
    content: " *";
    color: red;
}
#dropdown-split-variants-Success {
    border-radius: 18px;
    width: 100px;
    font-size: 13px;
}
#mytable {
    max-height: 610px;
}
#TablePagination {
    min-height: 51px;
    z-index: 1;
}
.modal-assoc__clz > .modal-content {
    max-height: 89vh !important;
}
.btn__clz {
    background: #f8f8fb 0% 0% no-repeat padding-box;
    border: 1px solid #e5e4e4;
    opacity: 1;
}
.card {
    margin-bottom: 0px !important;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.style-btn__clz {
    width: 100%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
}

.device-btn__clz {
    width: 100%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: #323333;
}

.MuiCheckbox-root {
    cursor: pointer !important;
    color: black !important;
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.pointer__clz {
    cursor: pointer;
    color: black !important;
}
.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}

@media (min-width: 0px) {
    @media (orientation: landscape) {
        .css-i6s8oy {
            min-height: 74px !important;
        }
    }
}
.css-hyum1k-MuiToolbar-root {
    min-height: 74px !important;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    margin-top: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}
