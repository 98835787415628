.checkbox__clz {
    width: 18px;
    height: 18px;
}

.table__orb__clz thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6 !important;
    border-top: 0px solid #dee2e6 !important;
}
