.category__clz {
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}

.category-card__clz {
    width: 57px;
    height: 56px;
    border-radius: 9px;
    box-shadow: 0px 3px 6px #00000029;
}

.category-swiper__clz.swiper-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.category-swiper__clz {
    transform: translateY(0);
    animation-name: fadeInDown;
    animation-duration: 2s;
}

.active-category-card__clz {
    width: 57px;
    height: 56px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--primary-color) 2px solid;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.active-category-card__clz::after {
    content: "";
    background: transparent
        linear-gradient(180deg, #ffffff00 50%, var(--primary-color) 100%) 0% 0%
        no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--primary-color) 2px solid;
    position: absolute;
    width: 66px;
    height: 55px;
    top: 0;
    left: 0;
    opacity: 0.3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.category-title__clz {
    font: normal normal bold 16px/21px Segoe UI;
    text-align: center;
    width: 70px;
    letter-spacing: 0px;
    color: #343434;
    text-transform: uppercase;
}

.active-category-title__clz {
    font: normal normal bold 10px/15px Segoe UI;
    text-align: center;
    width: 80%;
    letter-spacing: 0px;
    color: #343434;
    text-transform: uppercase;
}
