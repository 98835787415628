.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.style-buttom__clz {
    background-color: currentColor !important;
}
.modal-wrapper-title__clz {
    font-family: "Segoe UI Semibold";
    color: #2b2828;
    font-size: 20px;
}
