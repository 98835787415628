.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.not-allowed-input__clz div {
    pointer-events: none;
    opacity: 0.8;
    background-color: #c7cdcd !important;
}
#TablePagination {
    min-height: 64px;
    z-index: 1;
}
.not-allowed-input-pm__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: #c7cdcd !important;
}
