.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    background-color: #ffffffbf !important;
    border-radius: unset;
    padding: 0.5rem 1rem;
    padding-bottom: 0.5rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
}

.menu-wrapper-nav-link__clz {
    color: white;
    width: auto !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
    width: 100%;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.active-tab__clz {
    border-bottom: 2px solid #000;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.not-allowed-customer-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}